import axiosInstance from "../lib/axiosInstance";

const Faqmodel = class {
  constructor() {
    this.baseUrl = "/faq";
  }

  async list(query = []) {
    return (
      await axiosInstance.get(`${this.baseUrl}?${new URLSearchParams(query)}`)
    ).data;
  }

  async create(data = []) {
    return await axiosInstance.post(this.baseUrl, data);
  }
  async delete(id) {
    return await axiosInstance.delete(`${this.baseUrl}/${id}`);
  }
  async update(id, data = []) {
    return await axiosInstance.put(`${this.baseUrl}/${id}`, data);
  }
};

export default new Faqmodel();
